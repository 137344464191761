<template>
  <div class="orderList">
    <div class="orderHead">
      <div>
        <div class="venueTitle"> {{item.orderNum}} </div>
        <div class="venueType">
          <span v-if="item.isSelectedVenue">酒店会场</span>
          <span v-if="item.isSelectedMeal">酒店餐饮</span>
          <span v-if="item.isSelectedRoom">酒店住宿</span>
          <span v-if="item.isSelectedOther">酒店其他</span>
        </div>
      </div>
    </div>
    <div class="venueSuplier" v-if="item.suppliers && item.suppliers.length && [0, 10].includes(item.status)"  @click.stop="goQuotedPrice(item)">
      <div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-zuobiao"></use>
        </svg>
      </div>
      <div v-if="item.suppliers && item.suppliers.length" class="supplierName">
        <p v-for="(list, i) in item.suppliers" :key="i">{{list.name}}</p>
      </div>
    </div>
    <div class="venueSuplier2" v-if="![0,10].includes(item.status) && item.suppliers && item.suppliers.length">
      <div v-for="(list, i) in item.suppliers" :key="i" class="supplierName" @click.stop="goQuotedPrice(list)">
        <img :src="list.coverUrl || coverUrl" />
        <div class="selectSupplierInfo">
          <p class="title">{{list.name}}</p>
          <div class="priceInfo">
            <div class="supplierPrice">
              <span>报价</span>
              <span class="priceColor">{{(list.quoteAmount || 0) | currencyFormatter}}</span>
            </div>
            <div class="supplierPrice"  v-if="item.status >= 50 || item.status == -1">
              <span>结算</span>
              <span class="priceColor">{{(list.settleAmount || 0) | currencyFormatter}}</span>
            </div>
          </div>
        </div>
        <p class="tagSign">{{list.statusTxt}}</p>
      </div>
    </div>
    <div class="footerBtnCard">
      <div class="cardBtn" v-show="isShowUpdateFile(item)" @click.stop="goUpdatFile(item)">补传文件</div>
      <div class="cardBtn" v-show="item.status >= 40" @click.stop="goDetails(item)">查看需求</div>
      <div class="cardBtn" v-for="(li, index) in item.buttons" :key="index" @click.stop="actionClick(li)">{{ li.actName }}</div>
    </div>
    <span class="tagSign"> {{item.statusTxt}}</span>
  </div>
</template>
<script>
import {getCookie} from "tiny-cookie";
import generalBridge from "@/plugin/generalBridge";
import Prompt from '@/components/prompt/plugin';
export default {
  name: 'orderItem',
  props: {
    item: {
      type: Object,
      default () {
        return {
          supplierNames: [],
          orderSuppliers: [],
        }
      }
    }
  },
  data () {
    return {
      coverUrl: require('../../../assets/img/detailsImg.png'),
      tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
    }
  },
  computed: {
    isShowDelete () {
      let eventData = this.$SmartStorage.get('eventData') || {}
      return [50].includes(this.item?.status) || [0,10].includes(eventData.status)
    }
  },
  methods: {
    edit () {
      if([0].includes(this.item.status)) {
        this.$SmartStorage.set('orderId', this.item.orderId)
        this.$SmartStorage.set('venueList', this.item.suppliers || [])
        this.$router.push('/requirementsListOrder')
      } else if (this.item.status > 0 && this.item.status < 50) {
        this.$dialog.confirm({
          title: '提示',
          messageAlign: 'left',
          message: '是否确认修改需求？',
        }).then(async () => {
          Prompt.show({
            title: "提示",
            onConfirm: async (val) => {
              let params = {
                proposalId: this.$SmartStorage.get('proposalId'),
                supplierId:  this.item.suppliers[0].supplierId,
                reason: val,
                formData: JSON.stringify({supplierId:  this.item.suppliers[0].supplierId}),
                taskData: JSON.stringify({rebackReason: val}),
                operationType: 5,
                orderId: this.item.orderId,
              }
              let response = await this.$service.createTask(params);
              if (response.success) {
                this.$SmartStorage.set('orderId', this.item.orderId)
                this.$SmartStorage.set('venueList', this.item.suppliers || [])
                this.$router.push('/requirementsListOrder')
              }
            },
            message: `如果您修改需求，订单将撤回到草稿，需要重新提交供应商询价<br/>确认继续吗？`,
          })
        })
      }
    },
    goQuotedPrice (list = {}) {
      if (this.item.status <= 10) {
        this.$SmartStorage.set('orderId', this.item.orderId)
        this.$SmartStorage.set('venueList', this.item.suppliers || [])
        this.$router.push('/requirementsListOrder')
      } else if (this.item.status < 50) {
        this.$SmartStorage.set('supplierId', list.supplierId)
        this.$SmartStorage.set('orderId', this.item.orderId)
        this.$router.push('/quotedPriceDemandListOrder')
      } else {
        this.confirmSettle(list)
      }
    },
    confirmSettle (list = {}) {
      let item = this.item.suppliers.filter((ele) => ele.status == 50)[0]
      this.$SmartStorage.set('supplierId', list.supplierId || item.supplierId)
      this.$SmartStorage.set('orderId', this.item.orderId)
      if (this.item.taskInfo) {
        this.$SmartStorage.set('taskId', this.item.taskInfo.taskId)
        this.$SmartStorage.set('nodeId', this.item.taskInfo.nodeId)
        this.$SmartStorage.set('actionId', this.item.taskInfo.actionId)
      }
      this.$router.push( this.item.lossStatus && this.item.lossStatus >= 0 ? '/confirmLossOrder' : '/confirmCostOrder')
    },
    cancel () {
      this.$dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        message: '是否确认取消订单？',
      }).then(async () => {
        this.$dialog.confirm({
          title: '提示',
          messageAlign: 'left',
          message: `如果您取消订单，本订单中的所有需求都会被取消<br/>确认继续吗？`,
        }).then(async () => {
          let params = {
            orderId: this.item.orderId,
          };
          let response = await this.$service.updateLossStatus(params);
          if (response.success) {
            this.$toast(response.msg);
            this.$emit("againRendering", true);
          }
        }).catch(() => {});
      }).catch(() => {});
    },
    delete () {
      this.$dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        message: '是否确认取消订单？',
      }).then(async () => {
        this.$dialog.confirm({
          title: '提示',
          messageAlign: 'left',
          message: `如果您取消订单，本订单中的所有需求都会被取消<br/>确认继续吗？`,
        }).then(async () => {
          let params = {
            orderId: this.item.orderId,
          };
          let response = await this.$service.DeleteEOrders(params);
          if (response.success) {
            this.$toast("取消成功！");
            this.$emit("againRendering", true);
          }
        }).catch(() => {});
      }).catch(() => {});
    },
    actionClick (list) {
      this[list.actCode](list)
    },
    confirmQuote () {
      this.$dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        message: '所有的费用都已经报价，请确认酒店的取消条款，没有报价的未计入总价',
      }).then(async() => {
        let params = {
          taskId: this.item.taskInfo.taskId,
          nodeId: this.item.taskInfo.nodeId,
          actionId: this.item.taskInfo.actionId,
          processId: this.item.taskInfo.processId,
          orderId: this.item.orderId
        };
        let res = await this.$service.ProcessTask(params)
        if (res.success) {
          this.$toast("操作成功！");
          this.$emit("againRendering", true);
        }
      }).catch(() => {});
    },
    process () {
      this.$SmartStorage.set('orderId', this.item.orderId)
      this.$router.push({ path: "/projectProcess", query: { fromPage: 'orderList' }});
    },
    async toPay () {
      let params = {
        orderId: this.item.orderId,
        miceId: this.$SmartStorage.get('proposalId'),
        supplierId: this.item.suppliers[0].supplierId
      }
      let res = await this.$service.QueryEOrderSupplierPayInfo(params)
      if (res && res.success) {
        this.goPay(Object.assign(res.content, {orderId: this.item.orderId}), [20].includes(res.content.payMode) ? 'cashier' : 'paySelect')
      }
    },
    async goPay(data, url){
      let item = this.item.suppliers.filter((ele) => ele.status >= 50)[0]
      let params = {
        Data: {
          eventData: {
            proposalId: this.$SmartStorage.get('proposalId'),
          },
          itemData: {},
          tenant_code: this.tenant,
          isH5: this.$SmartStorage.get('isH5'),
          tenant_id: getCookie('tenant_id') || this.$SmartStorage.get('tenant_id'),
          tenant: this.tenant,
          token: getCookie('token'),
        }
      }
      let itemData = {
        orderId: item.orderId,
        proposalId: this.$SmartStorage.get('proposalId'),
        itemId: item.supplierId,
        name: item.name,
        settle: item.settleAmount,
        sessionId: this.$SmartStorage.get('sessionId'),
      }
      if (this.$SmartStorage.get('authMobile')) {
        itemData.authmobile = this.$SmartStorage.get('authMobile')
      }
      params.Data.itemData = Object.assign(data, itemData)
      let res = await this.$service.saveCacheData(params)
      if (res && res.success) {
        let ipayURL = `${this.$SmartStorage.get("Uris").Uris["webapp-ipay"]}/${url}?sessionId=${res.content}`
        generalBridge.openWebAppCate(ipayURL)
      }
    },
    uploadInvoice () {
      this.goPay({origin: "Venue", orderId: this.$SmartStorage.get('orderId')}, 'uploadInvoice')
    },
    goDetails (list) {
      this.$SmartStorage.set("orderId", list.orderId)
      this.$SmartStorage.set("venueList", list.suppliers)
      this.$router.push('/requirementsListOrder')
    },
    isShowUpdateFile (item) {
      let result = false
      let miceInfo = this.$SmartStorage.get("eventData");
      if (item.status > 50 && !['pfizer'].includes(this.tenant)) {
        result = true
      }
      if ([50,60].includes(miceInfo.status) && ['pfizer'].includes(this.tenant)) {
        result = (item.status > 50 && item.lossStatus <= 0) || (item.settleAmount>0 && item.lossStatus > 0)
      }
      return result
    },
    goUpdatFile (item) {
      // 过滤掉 status 为 -1 或 20 的供应商
      const validSuppliers = item.suppliers?.filter(supplier => supplier.status !== -1 && supplier.status !== 20) || [];
      const supplierId = validSuppliers.length > 0 ? validSuppliers[0].supplierId : '';
      let data = {
        proposalId: item.miceId,
        orderId: item.orderId,
        supplierId: supplierId,
        lossStatus: item.lossStatus,
        status: item.status,
      }
      this.$SmartStorage.set("selectDemandItem", data);
      this.$router.push({ path: "/supplementFile"});
    }
  }
}
</script>
<style scoped lang="scss">
.orderList {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .1rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  padding: .15rem;
  .orderNum {
    font-size: .12rem;
    color: #90939A;
    text-align: left;
  }
  .venueType {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: .12rem;
    span{
      font-size: .14rem;
      display: inline-block;
      margin-right: 0.2rem;
      color: #909399;
      .icon{
        color: #909399;
        width: .16rem;
        height: .16rem;
      }
    }
  }
  .venueSuplier {
    display: flex;
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-top:  1px solid #F5F5F5;
    padding: .12rem 0;
    margin-top: .12rem;
    .icon{
      width: .16rem;
      height: .16rem;
    }
    p{
      color: #13161B;
    }
  }
  .venueSuplier2{
    display: flex;
    text-align: left;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top:  1px solid #F5F5F5;
    padding: .12rem 0;
    margin-top: .12rem;
    .supplierName{
      background: #F5F5F5;
      box-shadow: 0px 5px 19px 0px rgba(8,37,105,0.1);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .1rem;
      margin: .08rem 0;
      width: 100%;
      position: relative;
      img{
        width: .5rem;
        height: .5rem;
        border-radius: .05rem;
      }
      .selectSupplierInfo {
        text-align: left;
        flex: 1 1 2%;
        margin-left: 0.1rem;
        width: 60%;
        .title{
          font-size: .16rem;
          font-weight: 500;
          color: #13161B;
          line-height: 17px;
        }
        .info{
          font-size: .12rem;
          color: #ccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .tagSign {
    color: #FECE30;
    text-align: left;
    padding: 0 0 0 0.1rem;
    position: absolute;
    right: 0.1rem;
    top: 0.05rem;
    font-size: .12rem;
  }
  .footerBtnCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: .12rem;
    border-top:  1px solid #F5F5F5;
    div {
      padding: .05rem 0;
      border: 1px solid #909399;
      font-size: .12rem;
      margin-left: .07rem;
      border-radius: .04rem;
      color: #909399;
      min-width: .6rem;
    }
  }
}
.priceInfo {
  text-align: left;
  .supplierPrice {
    display: inline-block;
    margin-right: .1rem;
    span{
      font-size: .13rem;
      font-weight: 500;
      color: #90939A;
    }
    .priceColor{
      font-size: .15rem;
      font-weight: 500;
      color: #13161A;
    }
  }
}
</style>
