<template>
  <div>
    <iHeader title="酒店订单" @back-event="closeWebview"/>
    <i-scrollBox class="opOrderListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
      <template v-slot:scrollBox>
        <orderitem v-for="(item, index) in orderList" :key="index" :item="item" @againRendering="getOrderList"></orderitem>
        <div class="noData" v-if="!orderList.length">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-NoDataicon"></use>
          </svg>
          <p>暂无订单</p>
        </div>
      </template>
    </i-scrollBox>
    <iAddButton v-show="isCanAdd" @click="addOrder"></iAddButton>
  </div>
</template>
<script>
import generalBridge from "@/plugin/generalBridge";
import orderitem from "@/view/orderList/components/orderitem";
import iScrollBox from "@/components/iScrollBox/iScrollBox";
import {getCookie} from "tiny-cookie";
export default {
  name: 'orderList',
  components: {
    orderitem,
    iScrollBox
  },
  data () {
    return {
      orderList: [],
      loadType: '',
      isCanAdd: true,
      tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
    }
  },
  created() {
    if (!this.$SmartStorage.get('proposalId')) {
      this.$SmartStorage.set('proposalId', this.$SmartStorage.get('eventData').proposalId)
    }
    this.$root.$eventHub.$on("againRendering", () => {
      this.getOrderList();
    });
    this.isCanAdd = [10,30].includes(JSON.parse(this.$cookie.get("eventData")).status) || [0].includes(JSON.parse(this.$cookie.get("eventData")).status) && ['pfizer'].includes(this.tenant)
    this.getOrderList()
  },
  methods: {
    closeWebview () {
      if (this.fromPage) {
        this.$router.back();
      } else {
        generalBridge.propy.call(this)
      }
    },
    scrollTop(){
      this.loadType = 'top'
      this.getOrderList()
    },
    scrollBottom () {
      this.getOrderList()
    },
    async getOrderList () {
      let params = {
        ProposalId: this.$SmartStorage.get('proposalId')
      }
      if (this.$SmartStorage.get('eventData').orderId) {
        params.OrderId = this.$SmartStorage.get('eventData').orderId
      }
      let res = await this.$service.PaingEOrder(params)
      if (res && res.success) {
        this.loadType = ''
        this.orderList = res.content || []
      }
    },
    async addOrder () {
      let params = {
        eventId: this.$SmartStorage.get('proposalId'),
      }
      let res = await this.$service.CreateHotelEOrder(params)
      if (res && res.success) {
        const {orderId} = res.content
        this.$SmartStorage.set("orderId", orderId)
        this.$SmartStorage.remove("venueList")
        this.$router.push('/requirementsListOrder')
      }
    },
  },
  beforeDestroy() {
    // 清除Bus
    this.$root.$eventHub.$off("againRendering");
  }
}
</script>
<style lang="scss" scoped>
.noData {
  margin-top: 1.5rem;
  svg{
    width: 2rem;
    height: 2rem;
  }
  p {
    font-size: .2rem;
    color: #c1c1c1;
  }
}
</style>
